
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Cards extends Vue {
  @Prop({ default: 4 })
  private readonly perRow!: number;

  @Prop({ default: 3 })
  private readonly perRowMd!: number;

  @Prop({ default: 2 })
  private readonly perRowSm!: number;
}
