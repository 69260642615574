
import { BlogModel } from 'tradingmate-modules/src/models/api/blogs'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Card from './Card.vue'
import Badge from '../status/Badge.vue'

@Component({
  components: {
    Card,
    Badge
  }
})
export default class BlogCard extends Vue {
  @Prop({ required: true })
  private readonly blog!: BlogModel

  get thumbnail (): string {
    let thumb = '/images/brand/placeholder.jpg'
    if (this.blog && this.blog.Images?.length) {
      thumb = this.blog.Images[0].Tiny
    }
    return thumb
  }
}
